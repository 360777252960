import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BetaSignUpForm from "../components/signup"

const LaunchSignup = () => {
  const [showGif, setShowGif] = useState(false)
  return (
    <Layout>
      <SEO title="godMode soft launch signup form" />

      <div
        className={
          " my-24 px-6 mx-auto max-w-6xl flex-wrap flex justify-center lg:justify-between"
        }
      >
        <div
          className={"basis-full md:basis-6/12 mb-8 md:mb-0 md:ml-8 lg:ml-0"}
        >
          <h1 className="h2 mb-4">The OGs</h1>
          <div className="p-md">
            We're getting ready to launch and only a handful of fortunate souls
            will get access to the game from Day 1. <br />
            <br />
            Don't worry!{" "}
            <span
              onMouseEnter={() => setShowGif(true)}
              onMouseLeave={() => setShowGif(false)}
              className="hover:cursor-pointer"
            >
              It'll be small at first, but grow quickly...
            </span>
            <br />
            <br />
            It's first come, first serve. So send us your info to get
            whitelisted and find out when you get game access.
            {showGif && (
              <img
                src="https://media.giphy.com/media/IJLVLpZQuS4z6/giphy.gif"
                alt="GIF"
              />
            )}
          </div>
        </div>
        <div className="flex justify-center mt-12 md:mt-0 md:h-80 lg:h-96 max-w-sm basis-full md:basis-3/12 lg:basis-2/5">
          <BetaSignUpForm />
        </div>
      </div>
    </Layout>
  )
}

export default LaunchSignup
