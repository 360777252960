import React, { useState } from "react"
import { createClient } from "@supabase/supabase-js"

const BetaSignUpForm = () => {
  const [name, setName] = useState("")
  const [walletAddress, setWalletAddress] = useState("")
  const [discord, setDiscord] = useState("")
  const [successfulSignup, setSuccessfulSignup] = useState(false)
  const [walletError, setWalletError] = useState(false)

  const supabase = createClient(
    process.env.GATSBY_SUPABASE_URL,
    process.env.GATSBY_SUPABASE_ANON_KEY
  )

  const isEthereumAddress = address => {
    return /^(0x)?[0-9a-fA-F]{40}$/.test(address) || /.+\.eth$/.test(address)
  }

  const handleNameChange = event => setName(event.target.value)
  const handleWalletAddressChange = event =>
    setWalletAddress(event.target.value)
  const handleDiscordChange = event => setDiscord(event.target.value)

  const handleSubmit = async event => {
    event.preventDefault()
    setWalletError(false)

    if (!isEthereumAddress(walletAddress)) {
      console.error("Invalid Ethereum Address")
      setWalletError(true)
      return
    }

    const { data, error } = await supabase.from("beta_users").insert({
      name,
      wallet_id: walletAddress,
      discord,
    })

    if (error) {
      console.error(error)
    } else {
      setSuccessfulSignup(true)
      setName("")
      setWalletAddress("")
      setDiscord("")
      setTimeout(() => setSuccessfulSignup(false), 3000)
    }
  }

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-md mx-auto">
      <div className="mb-4">
        <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
          What shall we call you?
        </label>
        <input
          required
          type="text"
          id="name"
          name="name"
          value={name}
          onChange={handleNameChange}
          className="border-slate-900 border-4 p-4 rounded basis-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="walletAddress"
          className="block text-gray-700 font-bold mb-2"
        >
          Eth address:
        </label>
        <p>{walletError && "Invalid Address"}</p>
        <input
          required
          type="text"
          id="walletAddress"
          name="walletAddress"
          value={walletAddress}
          onChange={handleWalletAddressChange}
          className="border-slate-900 border-4 p-4 rounded basis-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>

      <div className="mb-6">
        <label htmlFor="discord" className="block text-gray-700 font-bold mb-2">
          Discord:
        </label>
        <input
          required
          type="text"
          id="discord"
          name="discord"
          value={discord}
          onChange={handleDiscordChange}
          className="border-slate-900 border-4 p-4 rounded basis-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>

      <div className="flex items-center justify-center">
        <button
          type="submit"
          disabled={!name || !walletAddress || !discord}
          style={{ backgroundColor: "rgb(110 231 183 / var(--tw-bg-opacity))" }}
          className="border-slate-900 border-4 p-4 rounded basis-full bg-blue-500 hover:bg-blue-700 hover:text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          {successfulSignup ? "Received!" : "Submit"}
        </button>
      </div>
    </form>
  )
}

export default BetaSignUpForm
